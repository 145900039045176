import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { fetchService } from 'actions/services'

export interface Service {
    service_id: number
    name: string
    category_id: number
    // add more fields as needed
}

export const useService = (serviceId?: number | string) => {
    const dispatch = useAppDispatch()

    const service = useAppSelector((state) =>
        serviceId ? state.services.entities.services?.[serviceId] : null
    ) as Service | null

    const isLoading = useAppSelector((state) => state.services.isFetching)

    useEffect(() => {
        if (serviceId) {
            dispatch(fetchService(serviceId))
        }
    }, [dispatch, serviceId])

    return { service, isLoading }
}
