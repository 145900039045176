import { Divider, LoadingDots } from '@advanza/ui'
import { useReclaimsList, useReclaimStatusCounts } from 'hooks/reclaimHooks'
import Chart from 'react-google-charts'
import ReclaimPercentageTable from 'components/reclaims/ReclaimPercentageTable'
import React, { useEffect } from 'react'
import style from './reclaimTaskDetails.module.css'

interface ReclaimTaskDetailProps {
    reclaim: Record<string, any>
    providerId: Number
    notes: Record<string, any>
}

const ReclaimTaskDetails: React.FC<ReclaimTaskDetailProps> = ({ reclaim, providerId, notes }) => {
    const noteIds = reclaim.notes
    const lastNoteId = noteIds?.[noteIds.length - 1]
    const note = notes?.[lastNoteId]?.note || ''

    const { isFetchingReclaimStatusCounts, reclaimStatusCounts } =
        useReclaimStatusCounts(providerId)

    const statusChartData = React.useMemo(() => {
        if (!reclaimStatusCounts || Object.keys(reclaimStatusCounts).length === 0) return null

        return [
            ['Status', 'Count'],
            ['accepted', reclaimStatusCounts.accepted ?? 0],
            ['denied', reclaimStatusCounts.denied ?? 0],
        ]
    }, [reclaimStatusCounts, providerId])

    const {
        stats: { nrReclaimsPerReason = [] } = {},
        refresh,
        isFetching: isFetchingReasonsCount,
    } = useReclaimsList('provider_reclaims', {
        providerId: providerId,
    })

    const reasonChartData = [
        ['Reason', 'Count'],
        ...(
            nrReclaimsPerReason?.map(({ reason, amount }: { reason: string; amount: number }) => [
                reason || 'No reason',
                Number(amount),
            ]) ?? []
        ).sort((a: number[], b: number[]) => b[1] - a[1]),
    ]

    useEffect(() => {
        if (providerId) refresh()
    }, [providerId])
    return (
        <>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <div className={style.infoContainer}>
                        {reclaim.reason ?? 'Reason not specified'}
                    </div>
                    <Divider sm />
                    <div className={style.infoContainer}>
                        <ReclaimPercentageTable reclaim={reclaim} />
                    </div>
                </div>
                <div>
                    <div className={style.infoContainer}>{note ?? 'No Notes'}</div>
                </div>
            </div>
            <Divider sm />
            <div className="grid grid-cols-2 gap-4">
                <div>
                    {!isFetchingReclaimStatusCounts ? (
                        <Chart
                            width={'100%'}
                            height={'240px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={statusChartData ?? []}
                            options={{
                                title: 'Reclaim Decision Breakdown',
                                colors: ['#0ea66b', '#f42435'],
                                titleTextStyle: { fontSize: 14 },
                                chartArea: {
                                    width: '100%',
                                    height: '80%',
                                    top: 50,
                                },
                                legend: {
                                    textStyle: {
                                        fontSize: 12,
                                    },
                                },
                            }}
                        />
                    ) : (
                        <LoadingDots />
                    )}
                </div>
                <div>
                    {!isFetchingReasonsCount ? (
                        <Chart
                            width="100%"
                            height="240px"
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={reasonChartData}
                            options={{
                                title: 'Reclaims By Reason',
                                titleTextStyle: { fontSize: 14 },
                                chartArea: {
                                    width: '100%',
                                    height: '80%',
                                    top: 50,
                                },
                                legend: {
                                    textStyle: {
                                        fontSize: 12,
                                    },
                                },
                            }}
                        />
                    ) : (
                        <LoadingDots />
                    )}
                </div>
            </div>
        </>
    )
}

export default ReclaimTaskDetails
