import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { fetchRequest } from 'actions/requests'

export interface Request {
    request_id: number
    requests_options: []
    // add more fields as needed
}

export const useRequest = (
    requestId?: number | string,
    filterSource = 'default',
    force = false
) => {
    const dispatch = useAppDispatch()

    const request = useAppSelector((state) =>
        requestId ? state.requests.entities.requests?.[requestId] : null
    ) as Request | null

    const isLoading = useAppSelector((state) => state.requests.isFetching)

    useEffect(() => {
        if (requestId && (force || !request?.requests_options)) {
            dispatch(fetchRequest(requestId, filterSource, force))
        }
    }, [dispatch, requestId, filterSource, force, request?.requests_options])

    return { request, isLoading }
}
