import { Icon } from '@advanza/ui'
import Card from 'components/Card'
import AutoRow from 'components/misc/AutoRow'
import NotesFilter from 'components/notes/NotesFilter'
import NotesList from 'components/notes/NotesList'
import Pagination from 'components/Pagination'
import ReclaimsFilters from 'components/reclaims/ReclaimsFilters'
import UndoReclaimButton from 'components/reclaims/UndoReclaimButton'
import TableList from 'components/TableList'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useReclaimsList } from 'hooks/reclaimHooks'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import style from './ReclaimsList.module.css'
import { renderReclaimField } from 'utils/reclaimRenderers'

const definitions = {
    reclaim_id: { name: '#' },
    status: { name: 'status' },
    automated_check_status: { name: <Icon name="smart_toy" /> },
    firstThree: { name: <Icon name="whatshot" /> },
    prospect: { name: 'Prosp' },
    keyAccount: { name: 'Key' },
    businessName: { name: 'Name' },
    created: { name: 'Date' },
    leadName: { name: 'Name' },
    phoneNumber: { name: 'Phone' },
    matchDate: { name: 'Request date' },
    reason: { name: 'Reason' },
    nrReclaims: { name: '#rec' },
    nrReclaimsTotal: { name: '#recT' },
    reclaimPercentageTotal: { name: 'RecT%' },
    action: { name: 'Action' },
    notes: { name: 'Notes' },
}

function renderRow(id, entities, compact) {
    const { reclaims, notes } = entities
    const reclaim = reclaims[id]
    const providerUrl = `/service/providers/provider/${reclaim.providerId}/profile`
    const requestUrl = `/service/requests/request/${reclaim.requestId}`
    const filterIdAssociations = `reclaimNotes${id}`

    return {
        cols: Object.keys(definitions).map((key) =>
            renderReclaimField({ key, reclaim, providerUrl, requestUrl, compact, notes })
        ),
        expandable: (
            <Card expandableStyle>
                <AutoRow noWrap>
                    {(reclaim.accepted || reclaim.denied) && (
                        <UndoReclaimButton reclaim={reclaim} />
                    )}
                    {reclaim.extra_field && <span>{reclaim.extra_field}</span>}
                    <div style={{ width: '100%' }}>
                        <Card header="notes" icon="speaker_notes">
                            <NotesFilter
                                filterId={filterIdAssociations}
                                defaultOptions={{ reclaimIds: [id], pageSize: 3 }}
                                newEntityOptions={{ type: 'reclaims', foreign_key: id }}
                            />
                            <NotesList filterId={filterIdAssociations} />
                        </Card>
                    </div>
                </AutoRow>
            </Card>
        ),
        className: reclaim.automated_check_status === 'mailSent' && style.rowAutomatedCheckActive,
    }
}

const ReclaimsList = ({ filterId = 'all', defaultOptions = {}, ...r }) => {
    const { countries } = useSelector((state) => state.countries)
    const combinedOptions = useMemo(() => {
        return { ...defaultOptions, countries }
    }, [defaultOptions, countries])
    const {
        filter = {},
        currentPage,
        selectable = false,
        changeFilter,
        pageNr,
        ...rest
    } = useReclaimsList(filterId, combinedOptions)

    useEffect(() => {
        changeFilter({ countries })
    }, [countries])

    useEffect(() => {
        if (defaultOptions?.providerId) {
            changeFilter(combinedOptions)
        }
    }, [defaultOptions?.providerId])

    const rows = {}
    const compact = !!r.compact
    currentPage.forEach((id) => {
        rows[id] = renderRow(id, rest.entities, compact)
    })

    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <AutoRow>
                <ReclaimsFilters {...r} filter={filter} changeFilter={changeFilter} />
                {rest.count && (
                    <Pagination
                        current={pageNr}
                        pageSize={parseInt(filter.pageSize)}
                        count={parseInt(rest.count)}
                        onPageChange={(page) => changeFilter({ page })}
                    />
                )}
                <ExportButton filter={filter} />
            </AutoRow>
            <TableList
                {...r}
                {...rest}
                selectable={selectable}
                filter={filter}
                rows={rows}
                definitions={definitions}
            />
        </LoadBoxWrapper>
    )
}

export default ReclaimsList

const ExportButton = ({ filter }) => {
    const exportUrl = `/api/office/reclaims/export/?${new URLSearchParams(filter).toString()}`
    return (
        <a href={exportUrl} target="_blank" rel="noreferrer">
            Export
        </a>
    )
}
