import React, { Fragment } from 'react'
import { differenceInDays, format, isYesterday } from 'date-fns'
import localeNL from 'date-fns/locale/nl'

interface FormattedTimeProps {
    date?: string | Date | null
    format?: string
}

const getDate = (value?: string | Date | null): Date => {
    if (!value) return new Date(0)
    if (value instanceof Date) return value
    return new Date(value.replace(' ', 'T'))
}

const FormattedTime: React.FC<FormattedTimeProps> = ({ date, format: formatStr }) => {
    const parsedDate = getDate(date)
    const daysAgo = differenceInDays(new Date(), parsedDate)
    const isRecent = daysAgo < 8
    const isToday = daysAgo === 0

    const dateString = formatStr
        ? format(parsedDate, formatStr)
        : isYesterday(parsedDate)
        ? format(parsedDate, 'd MMM HH:mm')
        : format(parsedDate, isToday ? 'HH:mm' : isRecent ? 'd MMM HH:mm' : 'd MMM yyyy', {
              locale: localeNL,
          })

    return <Fragment>{dateString}</Fragment>
}

export default FormattedTime
