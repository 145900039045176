import { connect } from 'react-redux'
import { addNote, changeNoteFilter } from 'actions/notes'

function mapStateToProps(state, props) {
    const { filters, isFetching, counts } = state.notes
    const filter = filters[props.filterId]
    const count = filter && counts[filter.searchKey]
    return {
        filter,
        count,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFilter: (id, options) => dispatch(changeNoteFilter(id, options)),
        addNote: (options, filterId) => dispatch(addNote(options, filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
