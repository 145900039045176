import style from './reclaimPercentageTable.module.scss'

interface Props {
    reclaim: Record<string, any>
}

const ReclaimHoverText = ({ reclaim = {} }: Props) => {
    return (
        <table className={style.table}>
            <thead>
                <tr>
                    <th></th>
                    <th>reclaims</th>
                    <th>leads</th>
                    <th>%</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Last 3 months</td>
                    <td>{reclaim.nrReclaimsLast3Months}</td>
                    <td>{reclaim.nrLeadsLast3Months}</td>
                    <td>{reclaim.reclaimPercentageLast3Months}%</td>
                </tr>
                <tr>
                    <td>All time</td>
                    <td>{reclaim.nrReclaimsTotal}</td>
                    <td>{reclaim.nrLeadsTotal}</td>
                    <td>{reclaim.reclaimPercentageTotal}%</td>
                </tr>
            </tbody>
        </table>
    )
}

export default ReclaimHoverText
