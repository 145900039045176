import { call } from '@advanza/api'
import { useList } from 'hooks/listHooks'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import relcaimsSchema from '../schemes/reclaimsSchema'

export function useReclaimsList(filterId, defaultOptions) {
    return useList(
        filterId,
        {
            url: 'office/reclaims/get-list',
            schema: relcaimsSchema,
            reducerKey: 'reclaims',
        },
        defaultOptions
    )
}

export function answerReclaim(id, answer, reason, options = {}) {
    return function (dispatch, getState) {
        const reclaim = getState().reclaims.entities.reclaims[id]
        if (reclaim.accepted) {
            return Promise.resolve()
        }
        return call('office/reclaims/answer-reclaim-request/' + id + '/' + answer, {
            json: { reason: reason, ...options },
        }).then(() => {
            dispatch({ type: 'INVALIDATE_RECLAIMS' })
            return Promise.resolve()
        })
    }
}

export function useReclaimReasons() {
    const dispatch = useDispatch()
    const { isFetchingReclaimReasons, reclaimReasons } = useSelector((state) => state.reclaims)

    useEffect(() => {
        if (!isFetchingReclaimReasons && !Object.keys(reclaimReasons).length) {
            dispatch({ type: 'REQUEST_RECLAIM_REASONS' })
            call('reclaims/get-reclaim-reasons/0/1').then(({ reclaimReasons }) => {
                dispatch({ type: 'RECEIVE_RECLAIM_REASONS', reclaimReasons })
            })
        }
    })

    return { isFetchingReclaimReasons, reclaimReasons }
}

export function useReclaimStatusCounts(providerId) {
    const dispatch = useDispatch()

    const { isFetchingReclaimStatusCounts, reclaimStatusCounts } = useSelector(
        (state) => state.reclaims
    )

    useEffect(() => {
        if (providerId) {
            dispatch({ type: 'REQUEST_RECLAIM_STATUS_COUNTS' })
            call(`office/reclaims/get-reclaim-status-counts/${providerId}`)
                .then(({ reclaimStatusCounts }) => {
                    dispatch({ type: 'RECEIVE_RECLAIM_STATUS_COUNTS', reclaimStatusCounts })
                })
                .catch(() => {
                    dispatch({ type: 'RECEIVE_RECLAIM_STATUS_COUNTS', reclaimStatusCounts: {} })
                })
        }
    }, [providerId, dispatch])

    return {
        isFetchingReclaimStatusCounts,
        reclaimStatusCounts,
    }
}
