import React from 'react'
import { renderReclaimField } from 'utils/reclaimRenderers'

interface ReclaimBasicDetailsProps {
    reclaim: Record<string, any>
}

const ReclaimDetails: React.FC<ReclaimBasicDetailsProps> = ({ reclaim }) => {
    const displayInfo = {
        Status: renderReclaimField({ key: 'status', reclaim }),
        Value: renderReclaimField({ key: 'prospect', reclaim }),
        Key: renderReclaimField({ key: 'keyAccount', reclaim }),
        Company: renderReclaimField({ key: 'businessName', reclaim }),
        Time: renderReclaimField({ key: 'created', reclaim }),
        Match: renderReclaimField({ key: 'leadName', reclaim }),
        Phone: renderReclaimField({ key: 'phoneNumber', reclaim }),
        Date: renderReclaimField({ key: 'matchDate', reclaim }),
        Reason: renderReclaimField({ key: 'reason', reclaim }),
        Comment: renderReclaimField({ key: 'extra_field', reclaim }),
    }

    return (
        <div className="bg-grey-500 p-4 rounded-xl shadow-sm border border-grey-700">
            <div className="space-y-2 text-sm">
                {Object.entries(displayInfo).map(([label, value]) => (
                    <div key={label} className="flex justify-between gap-1">
                        <span className="text-dark capitalize font-bold">{label}:</span>
                        <span>{value}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ReclaimDetails
