import { S3_PUBLIC } from '@advanza/constants'
import Modal from '@advanza/modal'
import { Button, Divider, Icon } from '@advanza/ui'
import { saveNote } from 'actions/notes'
import AutoRow from 'components/misc/AutoRow'
import auroraStyle from 'css/aurora.module.css'
import { formatRelative } from 'date'
import { useChangeEntity } from 'hooks/entityHooks'
import React from 'react'
import style from './noteListItem.module.css'

const NoteListItem = ({ entities, id, touch, nextNote }) => {
    const { notes } = entities
    const note = notes[id]
    const [pinModalOpen, setPinModalOpen] = React.useState(false)
    const username = note.user ? `${note.user.first_name}  ${note.user.last_name}` : 'system'
    const isOwnedByUser = note.user && note.user.id === window._user.id
    const noAvatar = note.user && !note.user.avatar_filename
    const userAvatarUrl =
        !noAvatar && note.user && `${S3_PUBLIC()}users/${note.user.id}/${note.user.avatar_filename}`
    const { onChangeEntity, onSaveEntity } = useChangeEntity({
        store: 'notes',
        name: 'notes',
        entityId: id,
        saveFunc: saveNote,
    })
    const isLastPinnedNote = note.pinned && nextNote && !nextNote.pinned
    const className = [
        style.root,
        style[note.class],
        note.pinned ? style.pinned : '',
        note.pinned && note.class === 'warning' && auroraStyle.auroraAlarming,
        isLastPinnedNote ? style.lastPinned : '',
    ].join(' ')
    return (
        <div className={className}>
            <div
                className={[style.avatar, userAvatarUrl ? style.hasImage : ''].join(' ')}
                style={{ background: `url('${userAvatarUrl}') no-repeat center center /cover` }}>
                {!note.user && <Icon name="smart_toy" />}
                {noAvatar && <Icon name="face" />}
            </div>
            <div>
                <div
                    className={style.body}
                    style={{ cursor: isOwnedByUser ? 'pointer' : 'default' }}
                    onClick={isOwnedByUser && touch ? touch : undefined}>
                    <span style={{ whiteSpace: 'break-spaces' }}>{note.note}</span>
                </div>
                <small className={style.small}>
                    {note.pinned && (
                        <Icon
                            fontSize={14}
                            style={{ color: ' rgb(117, 103, 0)' }}
                            name="push_pin"
                        />
                    )}{' '}
                    {formatRelative(note.created)} {username}
                </small>{' '}
                {note.class && <Icon fontSize={18} name={note.class} />}
            </div>
            <div className={style.showOnHover}>
                <button onClick={() => setPinModalOpen(true)}>
                    <Icon name={note.pinned ? 'fmd_bad' : 'add_location_alt'} />
                </button>
            </div>
            <Modal open={pinModalOpen} close={() => setPinModalOpen(false)}>
                {note.pinned ? <h3>Unpin this note</h3> : <h3>Pin this note to the top?</h3>}
                <Divider l />
                <AutoRow>
                    <Button
                        onClick={() => {
                            onChangeEntity({ pinned: note.pinned ? 'false' : 1 })
                            onSaveEntity()
                            setPinModalOpen(false)
                        }}>
                        Yes
                    </Button>
                    <Button name="bordered" onClick={() => setPinModalOpen(false)}>
                        Cancel
                    </Button>
                </AutoRow>
            </Modal>
        </div>
    )
}

export default NoteListItem
