import { HoverText } from '@advanza/ui'
import ReclaimPercentageTable from 'components/reclaims/ReclaimPercentageTable'

interface Props {
    trigger: string
    reclaim: Record<string, any>
}

const ReclaimHoverText = ({ trigger, reclaim = {} }: Props) => {
    return (
        <HoverText trigger={trigger}>
            <ReclaimPercentageTable reclaim={reclaim} />
        </HoverText>
    )
}

export default ReclaimHoverText
