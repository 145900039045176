import { call } from '@advanza/api'
import { changeEntity } from 'actions/entities'
import { fetchProvider } from 'actions/providers'
import {
    changeSubscriptionFilter,
    fetchAllMunicipalities,
    fetchSubscription,
    findGeoIdsByRange,
    replaceSubscriptionMunicipalities,
    saveSubscriptionGeoids,
    toggleSubscriptionMunicipalityIds,
    toggleSubscriptionMunicipalityIdsMany,
} from 'actions/subscriptions'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function useProvider(id) {
    const dispatch = useDispatch()

    const { providers, ...entities } = useSelector((state) => state.providers.entities)
    const { services } = useSelector((state) => state.services.entities)
    const leadSettings = provider && entities.leadSettings[provider.lead_setting]

    const provider = id ? providers?.[id] : null
    const user = provider ? entities.users?.[provider.user] : null

    const isFranchiseMain = provider?.franchise_status > 0
    const useFranchiseBilling = !!provider?.payment_info_franchise

    // Sanitize the website URL
    const websiteWithProtocol = useMemo(() => {
        if (!provider?.website) return ''
        return provider.website.startsWith('http')
            ? provider.website
            : `http://${provider.website.toLowerCase()}`
    }, [provider?.website])

    useEffect(() => {
        if (id) {
            dispatch(fetchProvider(id))
        }
    }, [dispatch, id])

    return {
        provider,
        leadSettings,
        services,
        entities,
        user,
        isFranchiseMain, // Whether it's franchise a main account
        useFranchiseBilling, // Whether it's a franchise child account with shared billing,
        websiteWithProtocol,
    }
}

export function useProviderStatsSettings(id) {
    const { providers } = useSelector((state) => state.providers.entities)
    const [isSaving, setIsSaving] = useState(false)
    const dispatch = useDispatch()

    const onRemoveSlowLabel = () => {
        if (isSaving) {
            return false
        }
        setIsSaving(true)
        return call('office/providers/remove-slow-label/' + id, { method: 'post' })
            .then(() => {
                return call('office/providers/reset-stats-date/' + id, {
                    method: 'post',
                }).then(() => {
                    dispatch(fetchProvider(id, true))
                })
            })
            .finally(() => setIsSaving(false))
    }

    const onResetFreeTrialDayLimit = () => {
        if (isSaving) {
            return false
        }
        setIsSaving(true)
        return call('office/providers/reset-free-trial-day-limit/' + id, { method: 'post' })
            .then(() => {
                dispatch(fetchProvider(id, true))
            })
            .finally(() => setIsSaving(false))
    }

    return {
        onRemoveSlowLabel,
        onResetFreeTrialDayLimit,
        isSaving,
    }
}

export function useGetSubscriptions(providerId, fetch = false) {
    const dispatch = useDispatch()
    const filterId = 'p_' + providerId

    useEffect(() => {
        if (fetch) {
            dispatch(
                changeSubscriptionFilter(filterId, {
                    defaultOptions: { providerIds: [providerId] },
                })
            )
        }
    }, [dispatch, fetch, filterId, providerId])

    const { entities, result, filters } = useSelector((state) => state.subscriptions)
    const filter = filters[filterId] || {}
    const pages = result[filter.searchKey] || {}
    const results = pages[0] || []
    const subscriptionArr = results
        .map((id) => (entities.subscriptions || {})[id])
        .filter((subscription) => subscription)

    return {
        subscriptionArr,
        ...entities,
    }
}

export function useQualityScores(providerId) {
    const [isLoading, setIsLoading] = useState(false)
    const [debugInfo, setDebugInfo] = useState(false)
    const [didInvalidate, setDidInvalidate] = useState(false)
    const [isReCalcing, setIsReCalcing] = useState(false)
    const dispatch = useDispatch()
    const provider = useSelector(
        (state) =>
            state.providers.entities.providers && state.providers.entities.providers[providerId]
    )

    useEffect(() => {
        if ((provider && !provider.quality_scores) || didInvalidate) {
            setIsLoading(true)
            call('office/providers/get-quality-scores/' + providerId).then((response) => {
                dispatch(
                    changeEntity({
                        store: 'providers',
                        name: 'providers',
                        key: providerId,
                        diff: { quality_scores: response.items },
                    })
                )
                setDidInvalidate(false)
                setIsLoading(false)
            })
        }
    }, [didInvalidate])

    const invalidate = () => setDidInvalidate(true)
    const recalcQualityScore = (providerId) => {
        if (isReCalcing) {
            return Promise.resolve()
        }
        setIsReCalcing(true)
        return call(`office/providers/recalc-quality_scores/${providerId}`)
            .then((response) => {
                setIsReCalcing(false)
                setDebugInfo(response.out)
            })
            .finally(invalidate)
    }
    return {
        scores: (provider && provider.quality_scores) || [],
        recalcQualityScore,
        isLoading,
        debugInfo,
        statsStartDate: provider.stats_start_date,
        isReCalcing,
    }
}

export function useSubscriptionArea(subscriptionId, isLeadEstimator) {
    const dispatch = useDispatch()
    const [isSaving, setIsSaving] = useState(false)
    const { entities, isFetching } = useSelector((state) => state.subscriptions)
    const {
        result: municipalitiesGrouped,
        entities: municipalities,
        mapCenter,
    } = useSelector((state) => state.subscriptions.municipalities)

    useEffect(() => {
        if (!isLeadEstimator) {
            dispatch(fetchSubscription(subscriptionId)).then(() => {
                dispatch(fetchAllMunicipalities(subscription.country_code))
            })
        }
    }, [])
    const subscription = entities.subscriptions && entities.subscriptions[subscriptionId]
    const service = subscription && entities.services && entities.services[subscription.service_id]
    const postalCode = subscription && subscription.postal_codes && subscription.postal_codes[0]
    const subscriptionMunicipalities = subscription && subscription.subscriptions_municipalities
    const replaceMunicipalities = (newIds) => {
        dispatch(replaceSubscriptionMunicipalities(subscriptionId, newIds))
    }

    function toggleMunicipality(id) {
        dispatch(toggleSubscriptionMunicipalityIds(subscriptionId, id))
    }

    function setCenter(coords) {
        dispatch({ type: 'SERVICE_AREA_SET_MAP_CENTER', ...coords })
    }

    function toggleMunicipalityMany(ids, hasAll) {
        dispatch(toggleSubscriptionMunicipalityIdsMany(subscriptionId, ids, hasAll))
    }

    function setByRadius() {
        findGeoIdsByRange(
            postalCode.latitude,
            postalCode.longitude,
            postalCode.distance,
            subscription.country_code
        ).then((municipalityIds) => {
            setCenter({ ...postalCode, _blabla: 'yes' })
            replaceMunicipalities(municipalityIds)
            saveMunicipalities()
        })
    }

    const saveMunicipalities = () => {
        if (isLeadEstimator) {
            return Promise.resolve()
        }
        if (!isSaving) {
            setIsSaving(true)
            dispatch(saveSubscriptionGeoids(subscriptionId)).finally(() => setIsSaving(false))
        }
    }

    return {
        municipalitiesGrouped,
        toggleMunicipality,
        setCenter,
        replaceMunicipalities,
        subscriptionMunicipalities,
        saveMunicipalities,
        mapCenter,
        municipalities,
        isFetching,
        isSaving,
        setByRadius,
        toggleMunicipalityMany,
        postalCode,
        subscription,
        service,
    }
}
