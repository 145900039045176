import React from 'react'
import { Button, Divider, MaxWidth } from '@advanza/ui'
import Page from 'components/ui/Page'
import ReclaimsList from './ReclaimsList'
import { useHistory } from 'react-router-dom'

interface ReclaimsPageProps {
    filterId?: string
}

const ReclaimsPage: React.FC<ReclaimsPageProps> = ({ filterId = 'all' }) => {
    const history = useHistory()

    const handleReclaims = () => {
        history.push(`/billing/reclaim-tasks?filterId=${filterId}`)
    }

    return (
        <Page title="Reclaims" current={null} hideTop={false}>
            <MaxWidth>
                <Divider m />
                <div className="flex flex-row items-center gap-4">
                    <h1>Reclaims</h1>
                    <Button onClick={handleReclaims}>Handle Reclaims</Button>
                </div>
                <Divider l />
                <ReclaimsList filterId={filterId} defaultOptions={{ lastXMonths: 1 }} />
            </MaxWidth>
        </Page>
    )
}

export default ReclaimsPage
