import { format, parseISO } from 'date-fns'
import { Divider, HoverText, Icon, PreIcon } from '@advanza/ui'
import Balloon from 'components/ui/Balloon'
import A from 'components/ui/A'
import FormattedTime from 'components/ui/FormattedTime'
import ReclaimButtons from 'components/reclaims/ReclaimButtons'
import ReclaimHoverText from 'components/reclaims/ReclaimHoverText'

const getDate = (value?: string) => (value ? parseISO(value) : null)

export const renderReclaimField = ({
    key,
    reclaim,
    providerUrl,
    requestUrl,
    compact,
    notes,
}: {
    key: string
    reclaim: Record<string, any>
    providerUrl?: string
    requestUrl?: string
    compact?: boolean
    notes?: Record<string, { note: string }>
}) => {
    switch (key) {
        case 'businessName':
            return (
                <A className="classic" to={providerUrl || '#'} external={false}>
                    {reclaim.businessName}
                </A>
            )

        case 'status':
            if (reclaim.accepted) {
                return (
                    <HoverText trigger={<span className="text-secondary-800">Accepted</span>}>
                        <div>
                            <div>Reclaim accepted:</div>
                            <div>
                                {getDate(reclaim.accepted)
                                    ? format(getDate(reclaim.accepted)!, 'd-MMM-yyyy HH:mm')
                                    : '-'}
                            </div>
                            <div>Uncharge date:</div>
                            <div>
                                {getDate(reclaim.unChargedDate)
                                    ? format(getDate(reclaim.unChargedDate)!, 'd-MMM-yyyy HH:mm')
                                    : '-'}
                                {reclaim.isFreeLeadReclaim && (
                                    <>
                                        <Divider s />
                                        <small>
                                            This lead was a free lead, so it was already uncharged.
                                            <br />
                                            The number of free leads has been increased to
                                            compensate.
                                        </small>
                                    </>
                                )}
                            </div>
                            {reclaim.admin_comment && (
                                <>
                                    <div>Admin comment:</div>
                                    <div>{reclaim.admin_comment}</div>
                                </>
                            )}
                        </div>
                    </HoverText>
                )
            } else if (reclaim.denied) {
                return (
                    <HoverText trigger={<span className="text-tertiary-800">Rejected</span>}>
                        <div>
                            <div>
                                {getDate(reclaim.denied)
                                    ? format(getDate(reclaim.denied)!, 'd-MMM HH:mm')
                                    : '-'}
                            </div>
                            {reclaim.admin_comment && <div>{reclaim.admin_comment}</div>}
                        </div>
                    </HoverText>
                )
            } else {
                return <span className="text-tertiary-400">Open</span>
            }

        case 'firstThree':
            return !reclaim.accepted && !reclaim.denied && reclaim.nrReclaimsTotal <= 3 ? (
                <Balloon content="Within first 3 reclaims">
                    <Icon name="whatshot" />
                </Balloon>
            ) : (
                ''
            )

        case 'prospect':
            return ['€€€€', '€€€€€'].includes(reclaim.prospect || '') ? (
                <span style={{ whiteSpace: 'nowrap' }}>{reclaim.prospect}</span>
            ) : (
                ''
            )

        case 'keyAccount':
            if (!reclaim.keyAccountDesc) return '-'
            const iconMap: Record<string, string> = {
                key_account: '🥇',
                silver_account: '🥈',
                bronze_account: '🥉',
            }
            return (
                <Balloon content={reclaim.keyAccountDesc} style={{ fontSize: 20 }}>
                    {iconMap[reclaim.keyAccount] || '❓'}
                </Balloon>
            )

        case 'created':
            return (
                <small>
                    <FormattedTime date={reclaim[key]} />
                </small>
            )

        case 'leadName':
            return (
                <A className="classic" to={requestUrl || '#'} external={false}>
                    {reclaim.firstName}
                </A>
            )

        case 'phoneNumber':
            return reclaim.phoneNumber ? (
                <span>
                    <Icon name="phone" /> {reclaim.phoneNumber}
                </span>
            ) : (
                ''
            )

        case 'matchDate':
            return (
                <small>
                    {format(
                        new Date(reclaim.phoneNumber ? reclaim['phoneClickDate'] : reclaim[key]),
                        'd-MMM-yyyy'
                    )}
                </small>
            )

        case 'reason':
            return reclaim.extra_field ? (
                <HoverText trigger={reclaim[key]}>{reclaim.extra_field}</HoverText>
            ) : (
                reclaim[key]
            )

        case 'nrReclaims':
            return (
                <HoverText trigger={reclaim.nrReclaimsLast3Months}>
                    Nr reclaims last 3 months
                </HoverText>
            )

        case 'nrReclaimsTotal':
            return <HoverText trigger={reclaim.nrReclaimsTotal}>Total nr of reclaims</HoverText>

        case 'reclaimPercentageTotal':
            return (
                <ReclaimHoverText
                    trigger={reclaim.reclaimPercentageTotal + '%'}
                    reclaim={reclaim}
                />
            )

        case 'action':
            return <ReclaimButtons key={reclaim.reclaim_id} reclaim={reclaim} />

        case 'notes':
            if (reclaim.notes?.length) {
                const lastNoteId = reclaim[key]?.[reclaim[key].length - 1]
                const note = notes?.[lastNoteId]?.note
                const previewLength = compact ? 10 : 24
                if (note && note.length > previewLength) {
                    return (
                        <HoverText trigger={note.slice(0, previewLength) + '...'}>{note}</HoverText>
                    )
                }
                return note
            }
            return ''

        case 'extra_field':
            return reclaim.extra_field && <span>{reclaim.extra_field}</span>

        case 'automated_check_status':
            return (
                reclaim[key] && (
                    <PreIcon icon="smart_toy" overflowFix>
                        {reclaim[key].replace(/([A-Z])/g, ' $1').toLowerCase()}
                    </PreIcon>
                )
            )

        default:
            return reclaim[key] || ''
    }
}
