import { Icon } from '@advanza/ui'
import React from 'react'
import PropTypes from 'prop-types'

const CategoryIcon = ({ id, color, ...rest }) => {
    const iconMap = {
        1: 'park',
        2: 'lightbulb',
        3: 'local_dining',
        4: 'home',
        5: 'edit',
        6: 'search',
        7: 'work',
    }

    const colorProps = {
        green: color === 'green',
        red: color === 'red',
        grey: color === 'grey',
        orange: color === 'orange',
        white: color === 'white',
        primColor: color === 'blue',
    }

    const name = iconMap[id]

    return <Icon name={name} {...colorProps} {...rest} />
}

CategoryIcon.propTypes = {
    id: PropTypes.any.isRequired,
    color: PropTypes.string,
}

export default CategoryIcon
